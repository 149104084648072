import React from "react";
import { ThemeProvider } from "styled-components";
import PropTypes from "prop-types";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";

const theme = {
  animations: {
    duration: "0.5s",
  },
  fontFamily: "Inter, sans-serif",
  fontWeights: {
    semiBold: "600",
    bold: "700",
    extraBold: "800",
    black: "900",
  },
  breakpoints: {
    small: "576px",
    medium: "768px",
    large: "960px",
  },
  colors: {
    white: "#FFF",
    dark: "#131718",
    whiteOpaque: "rgba(255, 255, 255, 0.5)",
    border: "#363A3A",
    brand: "#FEDE01",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Theme;
