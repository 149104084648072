import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Component = styled.footer`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 5rem;
  padding-bottom: 5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    position: ${(props) => props.footerPosition};
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 150rem;
  padding: 0 1.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 0 6rem;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const ColumnOne = styled.div`
  width: 100%;
  order: 2;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    order: 1;
  }
`;

const ColumnTwo = styled.div`
  width: 100%;
  order: 1;
  margin-bottom: 2.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    justify-content: flex-end;
    order: 2;
    margin-bottom: 0;
  }
`;

const Copyright = styled.p`
  color: ${({ theme }) => theme.colors.whiteOpaque};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 1.6rem;
`;

const ExternalLink = styled.a`
  transition: ${({ theme }) => theme.animations.duration} color ease;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.whiteOpaque};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 1.6rem;
  text-decoration: none;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.brand};
  }
`;

const ExternalLinkText = styled.div`
  margin-right: 1.1rem;
`;

const Footer = ({ footerPosition }) => {
  return (
    <Component footerPosition={footerPosition}>
      <Container>
        <Grid>
          <ColumnOne>
            <Copyright>© Voxware, Inc.</Copyright>
          </ColumnOne>
          <ColumnTwo>
            <ExternalLink
              href="https://www.voxware.com/"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <ExternalLinkText>
                Visit voxware.com to learn more
              </ExternalLinkText>
              <svg
                width="16"
                height="11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.04.45a.715.715 0 00-.005 1.007l3.325 3.33H.705A.708.708 0 000 5.498c0 .394.317.711.705.711h12.65L10.03 9.54a.72.72 0 00.005 1.007.708.708 0 001-.006l4.507-4.539a.798.798 0 00.148-.224.713.713 0 00-.148-.771L11.036.467a.697.697 0 00-.995-.016z"
                  fill="#FFDF00"
                />
              </svg>
            </ExternalLink>
          </ColumnTwo>
        </Grid>
      </Container>
    </Component>
  );
};

Footer.propTypes = {
  footerPosition: PropTypes.string.isRequired,
};

export default Footer;
