import * as React from "react";
import PropTypes from "prop-types";
import "./layout.css";
import Theme from "./theme";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children, current, footerPosition }) => {
  return (
    <Theme>
      <Header current={current} />
      <main>{children}</main>
      <Footer footerPosition={footerPosition} />
    </Theme>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  current: PropTypes.string.isRequired,
  footerPosition: PropTypes.string.isRequired,
};

export default Layout;
